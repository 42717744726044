import * as Constants from "./constants";
import "./bindingHandlers/codeEditor";
import "./bindingHandlers/copyToClipboard";
import { UnsavedChangesRouteGuard } from "./routing/unsavedChangesRouteGuard";
import { MapiBlobStorage, MapiObjectStorage } from "./persistence";
import { ApimObjectStorageDecorator } from "./persistence/apimObjectStorageDecorator";
import { IInjector, IInjectorModule, InversifyInjector } from "@paperbits/common/injection";
import { ConsoleLogger, Logger } from "@paperbits/common/logging";
import { DefaultSessionManager } from "@paperbits/common/persistence/defaultSessionManager";
import { HistoryRouteHandler } from "@paperbits/common/routing";
import { ListOfApisDesignModule } from "./components/apis/list-of-apis/ko/listOfApis.design.module";
import { DetailsOfApiDesignModule } from "./components/apis/details-of-api/detailsOfApi.design.module";
import { HistoryOfApiDesignModule } from "./components/apis/history-of-api/historyOfApi.design.module";
import { SigninDesignModule } from "./components/users/signin/signin.design.module";
import { SigninSocialEditorModule as SigninSocialDesignModule } from "./components/users/signin-social/signinSocial.design.module";
import { SignupSocialDesignModule } from "./components/users/signup-social/signupSocial.design.module";
import { SignupDesignModule } from "./components/users/signup/signup.design.module";
import { ProfileDesignModule } from "./components/users/profile/profile.design.module";
import { SubscriptionsDesignModule } from "./components/users/subscriptions/subscriptions.design.module";
import { ProductDetailsDesignModule } from "./components/products/product-details/productDetails.design.module";
import { IdentityService } from "./services";
import { SetupModule } from "./components/setup/setup.module";
import { ContentModule } from "./components/content";
import { CustomWidgetListModule } from "./components/custom-widget-list";
import { OperationListEditorModule as OperationListDesignModule } from "./components/operations/operation-list/operationList.design.module";
import { OperationDetailsDesignModule } from "./components/operations/operation-details/operationDetails.design.module";
import { ProductListDesignModule } from "./components/products/product-list/productList.design.module";
import { ProductSubscribeDesignModule } from "./components/products/product-subscribe/productSubscribe.design.module";
import { ProductApisDesignModule } from "./components/products/product-apis/productApis.design.module";
import { ProductSubscriptionsDesignModule } from "./components/products/product-subscriptions/productSubscriptions.design.module";
import { App } from "./components/app/app";
import { ReportsDesignModule } from "./components/reports/reports.design.module";
import { ResetPasswordDesignModule } from "./components/users/reset-password/resetPassword.design.module";
import { ConfirmPasswordDesignModule } from "./components/users/confirm-password/confirmPassword.design.module";
import { HelpModule } from "./components/help";
import { ChangePasswordDesignModule } from "./components/users/change-password/changePassword.design.module";
import { TenantService } from "./services/tenantService";
import { ValidationSummaryDesignModule } from "./components/users/validation-summary/validationSummary.design.module";
import { StaticRoleService } from "./services/roleService";
import { ProvisionService } from "./services/provisioningService";
import { PolicyService } from "./services/policyService";
import { OAuthService } from "./services/oauthService";
import { OldContentRouteGuard } from "./routing/oldContentRouteGuard";
import { ApiProductsDesignModule } from "./components/apis/api-products/apiProducts.design.module";
import { RuntimeConfigurator } from "./services/runtimeConfigurator";
import { CustomHtmlDesignModule } from "./components/custom-html/customHtml.design.module";
import { CustomWidgetDesignModule } from "./components/custom-widget/customWidget.design.module";
import { CodeEditor } from "./components/code-editor/code-editor";
import { DefaultSettingsProvider } from "./configuration";
import { AzureResourceManagementService } from "./services/armService";
import { AccessValuesService } from "./services/accessValuesService";
import { ImpersonationService } from "./services/impersonationService";
import { GroupsService } from "./services/groupsService";
import { PageAccessRestrictedToastService } from "./services/pageAccessRestrictedToastService";
import { DesignerAccessRouteGuard } from "./routing/designerAccessRouteGuard";
import { AccessDesignModule } from "./access/access.design.module";
import { PageDesignModule } from "./components/pages/page.design.module";
import { PageService } from "./services/pageService";
import { StaticDelegationService } from "./services/staticDelegationService";
import { NoRetryStrategy } from "./clients/retryStrategy/noRetryStrategy";
import { ApimMediaService } from "./services/apimMediaService";
import { IMediaService } from "@paperbits/common/media";
import { HttpClient } from "@paperbits/common/http";
import { IAuthenticator } from "./authentication";
import { ViewManager } from "@paperbits/common/ui";
import { IObjectStorage } from "@paperbits/common/persistence";
import { UpdateDeprecatedStorageUriVisitor } from "./persistence/contractVisitors/updateDeprecatedStorageUriVisitor";
import { WrapperRegisterer } from "./logging/wrappers/wrapperRegisterer";

export class ApimDesignModule implements IInjectorModule {
    public register(injector: IInjector): void {
        injector.bindModule(new SetupModule());
        injector.bindModule(new ListOfApisDesignModule());
        injector.bindModule(new ApiProductsDesignModule());
        injector.bindModule(new DetailsOfApiDesignModule());
        injector.bindModule(new HistoryOfApiDesignModule());
        injector.bindModule(new SigninDesignModule());
        injector.bindModule(new SigninSocialDesignModule());
        injector.bindModule(new SignupSocialDesignModule());
        injector.bindModule(new SignupDesignModule());
        injector.bindModule(new ProfileDesignModule());
        injector.bindModule(new SubscriptionsDesignModule());
        injector.bindModule(new ProductListDesignModule());
        injector.bindModule(new ProductApisDesignModule());
        injector.bindModule(new ProductSubscriptionsDesignModule());
        injector.bindModule(new ProductDetailsDesignModule());
        injector.bindModule(new ProductSubscribeDesignModule());
        injector.bindModule(new OperationListDesignModule());
        injector.bindModule(new OperationDetailsDesignModule());
        injector.bindModule(new ReportsDesignModule());
        injector.bindModule(new ResetPasswordDesignModule());
        injector.bindModule(new ConfirmPasswordDesignModule());
        injector.bindModule(new ChangePasswordDesignModule());
        injector.bindModule(new ValidationSummaryDesignModule());
        injector.bindSingleton("app", App);
        injector.bindSingleton("logger", ConsoleLogger);
        injector.bindSingleton("tenantService", TenantService);
        injector.bindSingleton("roleService", StaticRoleService);
        injector.bindSingleton("provisioningService", ProvisionService);
        injector.bindSingleton("identityService", IdentityService);
        injector.bindSingleton("policyService", PolicyService);
        injector.bindSingleton("retryStrategy", NoRetryStrategy);
        injector.bindSingleton("objectStorage", MapiObjectStorage);
        injector.bindSingleton("blobStorage", MapiBlobStorage);
        injector.bindToCollection("routeGuards", OldContentRouteGuard);
        injector.bindToCollection("routeGuards", UnsavedChangesRouteGuard);
        injector.bindToCollection("routeGuards", DesignerAccessRouteGuard);
        injector.bindInstance("reservedPermalinks", Constants.reservedPermalinks);
        injector.bindSingleton("accessValuesService", AccessValuesService);
        injector.bindSingletonFactory("groupsService", (ctx) => {
            return new GroupsService(ctx.resolve("apiClient"), null);
        });
        injector.bindSingleton("impersonationService", ImpersonationService);
        injector.bindSingleton("pageAccessRestrictedToastService", PageAccessRestrictedToastService);

        injector.bindSingleton("oauthService", OAuthService);
        injector.bindToCollection("autostart", HistoryRouteHandler);
        injector.bindToCollection("autostart", RuntimeConfigurator);

        injector.bindSingleton("sessionManager", DefaultSessionManager);
                      
                                                                                  
                 
        injector.bindInstance("configFileUri", Constants.ConfigEndpoints.editor);
                  
        injector.bindSingleton("settingsProvider", DefaultSettingsProvider);
        injector.bindSingleton("armService", AzureResourceManagementService);
        injector.bindSingleton("delegationService", StaticDelegationService);
        injector.bind("CodeEditor", CodeEditor);
        injector.bindModule(new ContentModule());
        injector.bindModule(new HelpModule());
        injector.bindModule(new AccessDesignModule());
        injector.bindModule(new PageDesignModule());
        injector.bindSingleton("pageService", PageService);

        // disabled for multitenant
                      
                                                          
                                                            
                                                          
                  
    }
}

export class ApimDesignerOverridesModule implements IInjectorModule {
    public register(injector: IInjector): void {
                     
        const paperbitsMediaService = injector.resolve<IMediaService>("mediaService");
        injector.bindSingletonFactory("mediaService", (ctx) => new ApimMediaService(
            ctx.resolve<ViewManager>("viewManager"),
            ctx.resolve<AzureResourceManagementService>("armService"),
            ctx.resolve<IAuthenticator>("authenticator"),
            ctx.resolve<HttpClient>("httpClient"),
            paperbitsMediaService,
            ctx.resolve<DefaultSettingsProvider>("settingsProvider"),
            ctx.resolve<Logger>("logger")));
                  
    }
}

export class ApimDecoratorsModule implements IInjectorModule {
    public register(injector: IInjector): void {
        injector.bindCollection("objectContractVisitors");
        injector.bindToCollectionAsSingletone("objectContractVisitors", UpdateDeprecatedStorageUriVisitor);

        WrapperRegisterer.registerWrapper<IObjectStorage>(injector as InversifyInjector, "objectStorage", ApimObjectStorageDecorator);
    }
}