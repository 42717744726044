import { IComponent, ViewManager } from "@paperbits/common/ui";
import { accessSelectorKey } from "./accessConstants";
import { WidgetContext } from "@paperbits/common/editing";
import { IContextCommand } from "@paperbits/common/ui/IContextCommandSet";
import { IVisibilityContextCommandProvider } from "@paperbits/core/security/visibilityContextCommandProvider";
import { SecurityModelEditor } from "@paperbits/core/security/securityModelEditor";

export class AccessPopupSecurityModelEditorProvider implements IVisibilityContextCommandProvider {

    constructor(
        private readonly viewManager: ViewManager
    ) {}

    private createStandardVisibilityCommand(callback: IContextCommand["callback"], overrides?: Partial<Exclude<IContextCommand, "callback">>): IContextCommand {
        return {
            controlType: "toolbox-button",
            tooltip: "Change access",
            iconClass: "paperbits-icon paperbits-a-security",
            position: "top right",
            color: "#607d8b",
            ...overrides,
            callback,
        };
    }

    private createSecurityModelEditorComponent(context: WidgetContext, componentSelector: string): IComponent {
        const securityModelEditorParams: SecurityModelEditor = {
            securityModel: context.binding.model.security,
            onChange: (securityModel): void => {
                context.binding.model.security = securityModel;
                context.binding.applyChanges(context.model);
            },
        };
        return {
            name: componentSelector,
            params: securityModelEditorParams,
        };
    }

    create(context: WidgetContext): IContextCommand {
        return this.createStandardVisibilityCommand(() =>
            this.viewManager.openViewAsPopup({
                heading: "Visibility",
                component: this.createSecurityModelEditorComponent(context, accessSelectorKey),
                resizing: "vertically horizontally"
            }));
    }
}
